import { Typography } from "../../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../../app.module.scss"
import Button, { LinkButton } from '../../../ui/button/Button'

import succesImg from '../../../../assets/images/defaults/success-img.svg'
import closeImg from '../../../../assets/images/defaults/close-img.svg'

import './partnershipType.scss'

export default function PartnershipType(props) {
    const {type, children} = props
  return (
    <div className='partnershipType__item'>
        <Typography type="h3" className={classNames(styles.appTitle)}>{type}</Typography>
        <div className="partnershipType__box">
            <strong>Ce plan tarifaire est avantageux pour les jeunes entrepreneurs et leur permet de minimiser leurs coûts </strong>
            <div className="partnershipType__box__price">
                <div className="partnershipType__box__price__left">
                    <div>
                        <Typography type="h3" className={classNames(styles.appTitle)}>$0,99</Typography>
                        <span>par mois</span>
                    </div>
                    <div>
                        <Typography type="h3" className={classNames(styles.appTitle)}>$11,00</Typography>
                        <span>par an</span>
                    </div>
                </div>
                <div className="partnershipType__box__price__right">
                    <Typography type="h3" className={classNames(styles.appTitle)}>$25.00</Typography>
                    <span>votre prestation pour l'année</span>
                </div>
            </div>
            <div className="partnershipType__box__card">
                <ul>
                    <li>
                        <img src={succesImg} alt="success_img" />
                        <span>Nom de la prestation</span>
                    </li>
                    <li>
                        <img src={closeImg} alt="close_img" />
                        <span>Nom de la prestation</span>
                    </li>
                    <li>
                        <img src={closeImg} alt="close_img" />
                        <span>Nom de la prestation</span>
                    </li>
                    <li>
                        <img src={closeImg} alt="close_img" />
                        <span>Nom de la prestation</span>
                    </li>
                    <li>
                        <img src={closeImg} alt="close_img" />
                        <span>Nom de la prestation</span>
                    </li>
                    <li>
                        <img src={closeImg} alt="close_img" />
                        <span>Nom de la prestation</span>
                    </li>
                </ul>
            </div>
            <div className="partnershipType__box__action">
                <LinkButton to="#" text="Connecter" height='44px' size="14px" borderColor="none"/>
                <LinkButton to="#" text="En savoir plus" height='36px' size="12px" borderColor="#B0D7E2" bgColor="#454747"/>
            </div>
        </div>
        {children}
    </div>
  )
}
