import Input from "../../../ui/input/Input"
import "./formItem.scss"

export default function FormItemInput(props) {
  const {labelText,type,name,required,placeholder} = props
  const requiredStar = required? `*`:''
  //{dangerouslySetInnerHTML={__html:requiredTag}}
  return (
    <div className="form-row">
      <label htmlFor={name}>{labelText} <span>{requiredStar}</span></label>
      <Input
        typeInput={type}
        name={name}
        required={required??''}
        placeholder={placeholder??''}
      />
    </div>
  )
}
