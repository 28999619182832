import { Link } from 'react-router-dom'
import { Typography } from "../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../app.module.scss"
import FormItemInput from '../includes/formItem/FormItem'
import Button from "../../ui/button/Button"

import logo from '../../../assets/images/defaults/logo_only.svg'

import './login.scss'

export default function Login() {
  return (
    <section className="auth">
        <div className="auth__content auth-login">
            <Link to="/" className='auth__logo'>
              <img src={logo} alt="logo_only" />
              <div className='auth__logo__text'>
                <span>Rech</span>
                <span>Compt</span>
              </div>
            </Link>
            <Typography type="h2" className={classNames(styles.appTitle)}>
              Connexion
            </Typography>
            <div className="auth__card">
              <form action="#" className="auth__form">
                <FormItemInput 
                  labelText='E-mail*'
                  type="email"
                  required="required"
                  placeholder="e-mail"
                  name="email"
                />
                <FormItemInput 
                  labelText='Entrer le mot de passe*'
                  type="password"
                  required="required"
                  placeholder="entrer le mot de passe"
                  name="password"
                />
                <Button text="Entrer" size="18px" height="45px" borderColor="#008981"/>
              </form>
              <p>Mot de passe oublié? <Link to="/register">S'inscrire</Link></p>
            </div>
        </div>
    </section>
  )
}
