import { Container } from "../../../ui/container/Container"
import Button, {LinkButton} from "../../../ui/button/Button"
import { Typography } from "../../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../../app.module.scss"

import handeDouble from "../../../../assets/images/defaults/hande_double.svg"
import alertOk from "../../../../assets/images/defaults/alert_ok.svg"

import './plaqueSmall.scss'

export default function PlaqueSmall() {
  return (
    <section className="plaque-small">
        <Container>
            <div className="plaque-small__content">
                <div className="plaque-small__left">
                    <img className="double-hand" src={handeDouble} alt="hande_double" />
                    <img className="alert-ok" src={alertOk} alt="alert_ok" />
                </div>
                <div className="plaque-small__center">
                    <Typography type="h2" className={classNames(styles.appTitle)}>Devenir maintenant partenaire</Typography>
                </div>
                <div className="plaque-small__right">
                    <LinkButton
                        to="/" 
                        bgColor="none" 
                        borderColor="#fff" text="Devenir"
                        height="80px"
                        
                    />
                </div>
            </div>
        </Container>
    </section>
  )
}
