import { useState, useEffect } from "react"
import { Link, NavLink } from "react-router-dom"
import { Container } from "../../../ui/container/Container"
import Logo from "../../../../assets/images/defaults/logo.svg"
import './header.scss'
import userImg from "../../../../assets/images/defaults/user.svg"

export default function Header() {
  const [menuBtn, setMenuBtn] = useState(false)
  const [menuMobile, setMenuMobile] = useState(false)
  const [dropdown, setDropdown] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // HEADER ACTIVE
      const windowHeight = window.scrollY
      const header = document.querySelector("header.header")
      if(header){
        if(windowHeight > 75){
          if(!header.classList.contains('active')){
            header.classList.add('active')
          }
        }else{
          if(header.classList.contains('active')){
            header.classList.remove('active')
          }
        }
      }
      
      //PROGRESS BAR
      const bar = document.querySelector('.header__bar--progress')
      const max = document.body.scrollHeight - innerHeight
      const percent = (pageYOffset / max) * 100
      if(bar) bar.style.width = percent + '%'

    })
  },[])


  return (
    <header className="header"> 
      <Container>
        <div className="header__content">
          <Link to="/" className="header__logo">
              <img src={Logo} alt="rechcompt_logo" />
          </Link>
          <div className={`header__menu header-menu ${menuMobile ? 'active': ''}`}>
            <ul className="header-menu-list">
              <li className="header-menu-list__item">
                <NavLink to="/">Accueil</NavLink>
              </li>
              <li className="header-menu-list__item">
                <NavLink to="/services">Nos services</NavLink>
              </li>
              <li className="header-menu-list__item o-link-plus">
                <NavLink to="/faq">FAQ</NavLink>
              </li>
              <li className="header-menu-list__item">
                <NavLink to="/actualities">Actualités</NavLink>
              </li>
              <li className="header-menu-list__item o-link-plus">
                <NavLink to="/bonus">Bonus</NavLink>
              </li>
              <li className="header-menu-list__item">
                <NavLink to="/partnership">Partenariat</NavLink>
              </li>
              <li className="header-menu-list__item o-link-plus">
                <NavLink to="/games">Jeux et concours</NavLink>
              </li>
              <li className="header-menu-list__item">
                <NavLink to="/contacts">Contacts</NavLink>
              </li>
              <li className="header-menu-list__item o-link">
                <NavLink to="/shop">RC - Shop</NavLink>
              </li>
              <li className="header-menu-list__item o-link">
                <NavLink to="/games">RC - Jeux et concours</NavLink>
              </li>
              <li className="header-menu-list__item o-link">
                <NavLink to="/events">RC - Évènement</NavLink>
              </li>
              <li className="header-menu-list__item o-link">
                <NavLink to="/partnership">Partenariat</NavLink>
              </li>
            </ul>
            <div className="header-menu-action">
              <Link to="/login" className="header-menu-action__auth login">
                <img src={userImg} alt="login_user_image" />
                <span>Connexion</span>
              </Link>
              <Link to="/register" className="header-menu-action__auth register">Inscription</Link>
            </div>
          </div>
          <div className="header__action">
            <div className="header__action__rcplus dropdown">
              <Link to="/" onClick={(e)=> {e.preventDefault(), setDropdown(!dropdown)}}>RC+</Link>
              <ul className={`dropdown__list ${dropdown ? 'active': ''}`} >
                <li className="dropdown__list__item">
                  <Link to="#">RC - Shop</Link>
                </li>
                <li className="dropdown__list__item">
                  <Link to="/games">RC - Jeux et concours</Link>
                </li>
                <li className="dropdown__list__item">
                  <Link to="#">RC - Évènement</Link>
                </li>
                <li className="dropdown__list__item">
                  <Link to="/bonus">RC - Bonus</Link>
                </li>
              </ul>
            </div>
            <Link to="/login" className="header__action__auth login">
              <img src={userImg} alt="login_user_image" />
              <span>Connexion</span>
            </Link>
            <Link to="/register" className="header__action__auth register">Inscription</Link>

            <Link to="/" className={`header__menuBtn ${menuBtn ? 'open': ''}`} 
              onClick={(e)=> {
                e.preventDefault(),
                setMenuBtn(!menuBtn)
                setMenuMobile(!menuMobile)
              }}>
              <div className="header__menuBtn__line"></div>
            </Link>
          </div>
        </div>
      </Container>
      <div className="header__bar">
        <span className="header__bar--progress"></span>
      </div>
    </header>
  )
}
