import { Link } from 'react-router-dom'
import { Container } from "../../../ui/container/Container"
import { Icon } from "../../../ui/icons/Icon"
import styles from "../../../../app.module.scss"
import classNames from "classnames"
import './footer.scss'
import userImg from "../../../../assets/images/defaults/user.svg"

export default function Footer() {
  return (
    <footer className="footer">
      {/* <div className="footer__center"> */}
        {/* <Container> */}
          <div className="footer__center">
            <span>Au début</span>
            <div className="footer__center__card">
              <div className="footer__center__card__left">
                <strong>You can receive the latest news</strong>
                <p>about our service and all important updates to your mailbox</p>
                <div className="footer__center__card__form footer-form">
                  <form action="#" className="footer-form-box">
                    <input type="email" placeholder="E-mail" /><br/>
                    <button type="submit">Envoyer</button>
                  </form>
                </div>
              </div>
              <div className="footer__center__card__right footer-rs">
                <div className="footer-rs__list">
                  <Link to="/" className={classNames(styles.appIconBox)}>
                    <Icon name="instagram" size="1.5rem" className={classNames(styles.appIcon)}></Icon>
                  </Link>
                  <Link to="/" className={classNames(styles.appIconBox)}>
                    <Icon name="twitter" size="1.5rem" className={classNames(styles.appIcon)}></Icon>
                  </Link>
                  <Link to="/" className={classNames(styles.appIconBox)}>
                    <Icon name="facebook" size="1.5rem" className={classNames(styles.appIcon)}></Icon>
                  </Link>
                </div>
                <div className="footer-rs__text">
                  <strong>N'oubliez pas de vous abonner à nos canaux de médias sociaux</strong>
                  <p>C'est l'endroit le plus rapide pour être informé de nos mises à jour et de nos promotions.</p>
                </div>
              </div>
            </div>
          </div>
        {/* </Container> */}
      {/* </div> */}
      <div className="footer__bottom">
        <Container>
          <div className="footer__bottom__top">
            <div className='footer__bottom__left'>
              <span>Réalisé par</span>
              <strong>KevinpakCode</strong>
            </div>
            <div className='footer__bottom__right footer-menu'>
              <div className="footer-menu-action">
                <Link to="/login" className="footer-menu-action__auth login">
                  <img src={userImg} alt="login_user_image" />
                  <span>Connexion</span>
                </Link>
                <Link to="/register" className="footer-menu-action__auth register">Inscription</Link>
              </div>
            </div>
          </div>
          <div className="footer__bottom__bottom">
            <p>Copyrights ©RechCompt-2021</p>
          </div>
        </Container>
      </div>
    </footer>
  )
}
