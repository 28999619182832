import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
//import './button.scss'

export default function Input({typeInput, bgColor, placeholder, height,name, ...props}) {
  //const colorPrimary = '#fff'
  const bgColorPrimary =   '#fff'
  const type="text"

  const useStyles = createUseStyles({
    input: {
      display:'block',
      border:'none',
      borderRadius:'8px' ,
      height: height??'42px',
      paddingLeft:'10px',
      //color: color??colorPrimary,
      background: bgColor??bgColorPrimary,
      width:"100%",
      //fontSize: size??'24px',
    }

  })
  
  const classes = useStyles()
  return (
    <input type={typeInput??type} name={name} className={classNames(classes.input)} placeholder={placeholder??''} {...props} />
  )
}
