import { useEffect } from "react"
import PlaqueRegister from "../includes/plaqueRegister/PlaqueRegister"
import Actuality from "../includes/actuality/Actuality"
import { Container } from "../../ui/container/Container"
import Button from "../../ui/button/Button"
import { Typography } from "../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../app.module.scss"
import Input from "../../ui/input/Input"
import Layout from "../layouts/Layout"

import actu from '../../../assets/images/defaults/actu.png'

import './actualities.scss'

export default function Actualities() {

  const handleClickInputRadio = (e) => {
    const currentTarget = e.currentTarget
    if(currentTarget.classList.contains('active')){
      currentTarget.classList.remove('active')
    }else{
      currentTarget.classList.add('active')
    }
  }

  useEffect(() => {
    document.title = `Rechcompt | Actualités`;
  })
  return (
    <Layout>
      <section className="banner">
        <Container>
          <div className="banner__content banner__content-actu">
            <div className="banner__left">
              <Typography type="h1" className={classNames(styles.appTitle)}>Nos actualités</Typography>
              <Typography type="h3" className={classNames(styles.appTitle)}>Pour une recherche plus pratique</Typography>
              <p>vous pouvez trier les nouvelles par sujet d'intérêt</p>
              <select name="" id="">
                <option value="1">Toutes les catégories</option>
              </select>
            </div>
            <div className="banner__right banner-right">
              <Typography type="h3" className={classNames(styles.appTitle)}>Recherche par mot-clé</Typography>
              <form action="#" className="banner-right__form">
                <div className="banner-right__form__left">
                  <Input
                    name="key_word"
                    required='required'
                  />
                  <div className="banner-right__form__check">
                    <p>Je veux obtenir un résultat dans</p>
                    <div>
                      <Input
                        type="radio"
                        name="key_word"
                        required='required'
                        width="42px"
                        onClick={handleClickInputRadio}
                      />
                      <span>Vidéo</span>
                    </div>
                    <div>
                      <Input
                        type="radio"
                        name="key_word"
                        required='required'
                        onClick={handleClickInputRadio}
                      />
                      <span>Texte</span>
                    </div>
                  </div>
                </div>
                <div className="banner-right__form__right">
                  <Button borderColor="#008981" height="42px" size="18px" text="Localiser"/>
                </div>
              </form>
            </div>
          </div>
        </Container>
      </section>
      <section className="actualities">
        <Container>
          <div className="actualities__content">
            <div className="actualities__list">
              <Actuality
                image={actu}
                category="Nom de la catégorie"
                title="Un titre dont vous avez tout compris et plus encore"
                text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
              />
              <Actuality
                image={actu}
                category="Nom de la catégorie"
                title="Un titre dont vous avez tout compris et plus encore"
                text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
              />
              <Actuality
                image={actu}
                category="Nom de la catégorie"
                title="Un titre dont vous avez tout compris et plus encore"
                text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
              />
              <Actuality
                image={actu}
                category="Nom de la catégorie"
                title="Un titre dont vous avez tout compris et plus encore"
                text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
              />
              <Actuality
                image={actu}
                category="Nom de la catégorie"
                title="Un titre dont vous avez tout compris et plus encore"
                text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
              />
              <Actuality
                image={actu}
                category="Nom de la catégorie"
                title="Un titre dont vous avez tout compris et plus encore"
                text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
              />
              <Actuality
                image={actu}
                category="Nom de la catégorie"
                title="Un titre dont vous avez tout compris et plus encore"
                text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
              />
              <Actuality
                image={actu}
                category="Nom de la catégorie"
                title="Un titre dont vous avez tout compris et plus encore"
                text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
              />
              <Actuality
                image={actu}
                category="Nom de la catégorie"
                title="Un titre dont vous avez tout compris et plus encore"
                text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
              />
            </div>
          </div>
        </Container>
      </section>
      <PlaqueRegister/>
    </Layout>
  )
}
