import { Typography } from '../../../ui/typography/Typography'
import classNames from 'classnames'
import styles from "../../../../app.module.scss"
import Button, { LinkButton } from '../../../ui/button/Button'

import './actuality.scss'


export default function Actuality(props) {
    const {image, category, title, text} = props
  return (
    <div className='actualities__item'>
        <div className="actualities__img">
            <img src={image} alt="image_actualité" />
        </div>
        <div className="actualities__box">
            <small>{category}</small>
            <Typography type="h4" className={classNames(styles.appTitle)}>{title}</Typography>
            <p>{text}</p>
            <LinkButton 
                to="/"
                bgColor="linear-gradient(150deg, #525252 3.86%, #1B1B1B 54.94%)" 
                text="Lire la suite"
                size="14px"
            />
        </div>
    </div>
  )
}
