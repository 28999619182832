import { combineReducers } from 'redux'
import { defaultReducer } from './defaultReducer'
// import { AuthReducer } from './Auth'
// import { tagsReducer, categoriesTagsReducer } from './tags'
// import { sitesReducer } from './sites'



export const appReducer = combineReducers({
  defaultReducer
  // AuthReducer,
  // tagsReducer,
  // categoriesTagsReducer,
  // sitesReducer,
  
})

export const rooteReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}