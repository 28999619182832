import { useEffect } from "react"
import { Container } from "../../ui/container/Container"
import Button, {LinkButton} from "../../ui/button/Button"
import Game from "../includes/game/Game"
import { Typography } from "../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../app.module.scss"
import PlaquePhone from "../includes/plaquePhone/PlaquePhone"
import PlaqueRegister from "../includes/plaqueRegister/PlaqueRegister"
import Layout from "../layouts/Layout"

import gameBlack from '../../../assets/images/defaults/gameBlack.png'

import './games.scss'

export default function Games() {
  useEffect(() => {
    document.title = `Rechcompt | Jeux et concours`;
  })
  return (
    <Layout>
      <section className="banner">
        <Container>
          <div className="banner__content">
            <div className="banner__left">
              <Typography type="h1" className={classNames(styles.appTitle)}>Jeux et concours passés sur RechCompt ?</Typography>
              <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus.
              </p>

            </div>
            <div className="banner__right banner__right-game">
              <div className="banner__right-game__left">
                <Typography type="h2" className={classNames(styles.appTitle)}>Nom du jeux concours</Typography>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus.</p>
                <LinkButton to="/" text="Participer" />
              </div>
              <div className="banner__right-game__right">
                <img src={gameBlack} alt="" />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="games">
        <Container>
          <div className="games__content">
          <Typography type="h2" className={classNames(styles.appTitle)}>Jeux et concours passés sur RechCompt ?</Typography>
            <div className="games__card">
              <div className="games__list">
                <Game />
                <Game />
                <Game />
                <Game />
                <Game />
                <Game />
                <Game />
                <Game />
                <Game />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <PlaqueRegister/>
      <PlaquePhone/>
    </Layout>
  )
}
