import play from "../../../../assets/images/defaults/play-button.svg"

import './playButton.scss'

export default function PlayButton(props) {
    const {width} = props
  return (
    <>
        <img src={play} alt="play_button" style={{ width:width }} />
    </>
  )
}
