import { Link } from "react-router-dom"
import { Container } from "../../../ui/container/Container"
import Button, { LinkButton } from "../../../ui/button/Button"
import { Typography } from "../../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../../app.module.scss"
import './defaultBanner.scss'
import bannerSubImg1 from '../../../../assets/images/defaults/shield.svg'
import bannerSubImg2 from '../../../../assets/images/defaults/ok.svg'
import bannerImg from '../../../../assets/images/defaults/banner_image.png'
import logo from '../../../../assets/images/defaults/logo.svg'
import bannerCardImg1 from '../../../../assets/images/defaults/banner-badge1.svg'
import bannerCardImg2 from '../../../../assets/images/defaults/banner-badge2.svg'

export default function DefaultBanner(props) {
  const {title, text, cardTitle1, cardTitle2, cardText1, cardText2} = props

  return (
    <section className="banner">
      <Container>
        <div className="banner__content">
          <div className="banner__left">
            <Typography type="h1" className={classNames(styles.appTitle)}>{title}</Typography>
            <p>{text}</p>
            <LinkButton url="/login" text="Acheter"/>
            <div className="banner__left__info">
              <div className="banner__left__info__item">
                <strong>{cardTitle1}</strong>
                <p>{cardText1}</p>
                <div className="banner__left__info__item__subImg">
                  <span>
                    <img src={bannerSubImg1} alt="shield__image" />
                  </span>
                </div>
              </div>
              <div className="banner__left__info__item">
                <strong>{cardTitle2}</strong>
                <p>{cardText2}</p>
                <div className="banner__left__info__item__subImg">
                  <span>
                    <img src={bannerSubImg2} alt="ok__image" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="banner__right">
            <div className="banner__right__card">
              <div className="banner__right__card__slide banner-slide">
                <div className="banner-slide__item">
                  <img src={bannerImg} alt="banner_big_img" />
                  <div className="banner-slide__item__info">
                      <div className="logo">
                        <img src={logo} alt="rechcompt_logo" />
                      </div>
                      <p>
                        Depuis plus de 3 ans, nous sommes votre garant pour les transactions en ligne et les transferts d'argent transnationaux.
                      </p>
                      <div className="sub-img">
                        <img src={bannerCardImg1} alt="carte_1" />
                        <img src={bannerCardImg2} alt="carte_2" />
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
