import {
  SWITCH_PRIMARY_SIDEBAR,
  OPEN_PRIMARY_SIDEBAR,
  CLOSE_PRIMARY_SIDEBAR,
  OPEN_MAIN_SIDEBAR,
  CLOSE_MAIN_SIDEBAR,
  SET_OPENNED_TAG_CATEGORY_ID
} from '../types'

const initialState = {
  primarySidebarIsOpen: true,
  mainSidebarIsOpen: true,
  opennedTagCategoryId: null
}

export const defaultReducer = (state=initialState, action) => {
  switch (action.type) {

    case SWITCH_PRIMARY_SIDEBAR:
      // return {
      //   ...state,
      //   primarySidebarIsOpen: action.data
      // }
      break
    case OPEN_PRIMARY_SIDEBAR:
      // return {
      //   ...state,
      //   primarySidebarIsOpen: true
      // }
      break

    case CLOSE_PRIMARY_SIDEBAR:
      // return {
      //   ...state,
      //   primarySidebarIsOpen: false
      // }
      break

      case OPEN_MAIN_SIDEBAR:
        // return {
        //   ...state,
        //   mainSidebarIsOpen: true
        // }
        break
      
      case CLOSE_MAIN_SIDEBAR:
        // return {
        //   ...state,
        //   mainSidebarIsOpen: false
        // }
        break
      case SET_OPENNED_TAG_CATEGORY_ID:
        // return {
        //   ...state,
        //   opennedTagCategoryId: action.data
        // }
        break
    default:
      //return state
      break
  }
  return state
}