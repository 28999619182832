import { useEffect } from "react"
import { Link } from "react-router-dom"
import { Container } from "../../ui/container/Container"
import { Typography } from "../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../app.module.scss"
import { LinkButton } from "../../ui/button/Button"

import loupe from '../../../assets/images/defaults/loupe.svg'
import alert from '../../../assets/images/defaults/404.svg'

import "./notfound.scss"

export default function Notfound() {
    useEffect(() => {
        document.title = `Rechcompt | 404`;
    })
  return (
    
        <section className="notfound">
            <section className="notfound__top">
                <Container>
                    <div className="notfound__top__content">
                        <div className="notfound__top__card">
                            <p><span>Error404</span>(){'{'}</p>
                            <p><span className="red">message</span> = “<span className="green">page not found</span>”;</p>
                            <p>{'}:'}</p>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="notfound__bottom">
                <Container>
                    <div className="notfound__bottom__content">
                        <Typography type="h2" className={classNames(styles.appTitle)}>Désolé, la page n'a pas été trouvée ou il y a eu une erreur dans le lien.</Typography>
                        <p>Vous pouvez revenir à la <br/>page <Link to="/">d'accueil</Link></p>
                        <LinkButton url="/" text="Accueil" borderColor="#04C8A5" />
                        <img src={alert} alt="alert_img" />
                        <img src={loupe} alt="loupe_img" />
                    </div>
                </Container>
            </section>
        </section>
    
  )
}
