import { Container } from "../../ui/container/Container"
import DefaultBanner from "../includes/defaultBanner/DefaultBanner"
import { Typography } from "../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../app.module.scss"
import PlaqueRegister from "../includes/plaqueRegister/PlaqueRegister"
import PlaquePhone from "../includes/plaquePhone/PlaquePhone"
import Layout from "../layouts/Layout"
import './services.scss'

import workStep1 from '../../../assets/images/defaults/work-step1.svg'
import workStep2 from '../../../assets/images/defaults/work-step2.svg'
import workStep3 from '../../../assets/images/defaults/work-step3.svg'
import workStep4 from '../../../assets/images/defaults/work-step4.svg'
import subImg1 from '../../../assets/images/defaults/shield.svg'
import subImg2 from '../../../assets/images/defaults/ok.svg'
import yellowMoney from '../../../assets/images/defaults/yellow_money.svg'
import globe from '../../../assets/images/defaults/globe.svg'
import carton from '../../../assets/images/defaults/carton.svg'
import spin from '../../../assets/images/defaults/spin.svg'
import sattelite from '../../../assets/images/defaults/satellite.svg'
import moneyPaper from '../../../assets/images/defaults/money-paper1.svg'
import keepMoney from '../../../assets/images/defaults/keep_money.svg'

export default function Services() {
  return (
    <Layout>
        <DefaultBanner
            title="RechCompt — est un système financier"
            text="sert d'intermédiaire entre les commerçants et leurs clients"
            cardTitle1="Je suis fatigué de trouver des paroles"
            cardText1="Vous trouverez toujours quelque chose à écrire ici"
            cardTitle2="Pratique, sûr, anonyme"
            cardText2="Un partenaire fiable pour les transactions en ligne"
        />
        <section className="services">
            <Container>
                <div className="services__content">
                    <Typography type="h2" className={classNames(styles.appTitle)}>Nos services:</Typography>
                    <div className="services__card">
                        <div className="services__left">
                            <div className="infos__item">
                                <div className="infos__item__left">
                                    <img src={yellowMoney} alt="yellowMoney" />
                                    <img src={keepMoney} alt="keepMoney" />
                                    <span>1</span>
                                </div>
                                <div className="infos__item__right">
                                    <Typography type="h3" className={classNames(styles.appTitle)}>Intermédiation de d’achat</Typography>
                                    <p>entre clients et marchant via “code” ou “porte monnaie”. Choisissez l'option qui répond à vos besoins et profitez des achats en ligne avec un maximum d'avantages et de commodité.</p>
                                </div>
                            </div>
                            <div className="infos__item">
                                <div className="infos__item__left">
                                    <img src={globe} alt="globe" />
                                    <img src={sattelite} alt="sattelite" />
                                    <img src={moneyPaper} alt="moneyPaper1" />
                                    <img src={moneyPaper} alt="moneyPaper2" />
                                    <span>2</span>
                                </div>
                                <div className="infos__item__right">
                                    <Typography type="h3" className={classNames(styles.appTitle)}>Transfert d’argent International</Typography>
                                    <p>Recevoir des virements et envoyer de l'argent dans d'autres pays. La géographie de notre présence est en constante expansion.</p>
                                </div>
                            </div>
                            <div className="infos__item">
                                <div className="text">
                                    <span>A</span>
                                </div>
                                <div className="infos__item__right">
                                    <Typography type="h3" className={classNames(styles.appTitle)}>Systeme de code:</Typography>
                                    <p>Les marchands peuvent créer une page avec nous et ajouter des articles à vendre, en utilisant notre service comme garant d'une transaction sécurisée.</p>
                                </div>
                            </div>
                            <div className="infos__item">
                                <div className="infos__item__left">
                                    <img src={spin} alt="spin" />
                                    <img src={moneyPaper} alt="moneyPaper1" />
                                    <img src={moneyPaper} alt="moneyPaper2" />
                                    <img src={moneyPaper} alt="moneyPaper3" />
                                    <span>3</span>
                                </div>
                                <div className="infos__item__right">
                                    <Typography type="h3" className={classNames(styles.appTitle)}>Liens de paiement</Typography>
                                    <p>Un moyen rapide, sûr et pratique de recevoir des paiements pour des biens et des services. Vous pouvez envoyer un lien via les réseaux sociaux, les messageries, les e-mails ou les sms.</p>
                                </div>
                            </div>
                            <div className="infos__item">
                                <div className="infos__item__left">
                                    <img src={carton} alt="carton" />
                                    <span>4</span>
                                </div>
                                <div className="infos__item__right">
                                    <Typography type="h3" className={classNames(styles.appTitle)}>Mini page marchant de vente en ligne</Typography>
                                    <p>Les marchands peuvent créer une page avec nous et ajouter des articles à vendre, en utilisant notre service comme garant d'une transaction sécurisée.</p>
                                </div>
                            </div>
                            <div className="infos__item shield">
                                <div className="text">
                                    <span>B</span>
                                </div>
                                <div className="infos__item__right">
                                    <Typography type="h3" className={classNames(styles.appTitle)}>Système de porte monnaies:</Typography>
                                    <p>Chaque client inscrit chez nous à un porte monnaie dans lequel il peut stocker de l’argent. Cet argent peut être utilisé pour soit acheter des codes chez nous, soit pour effectuer des achats chez nos partenaires.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <PlaqueRegister/>
        <PlaquePhone/>
    </Layout>
  )
}
