import { Container } from "../../../ui/container/Container"
import Button, { LinkButton } from "../../../ui/button/Button"
import { Typography } from "../../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../../app.module.scss"

import './plaqueRegister.scss'

import registerMan from "../../../../assets/images/defaults/register-man.svg"

export default function PlaqueRegister() {
  return (
    <section className="plaque">
        <Container>
            <div className="plaque__content">
                <div className="plaque__left">
                    <Typography type="h2" className={classNames(styles.appTitle)}>Choisissez l'option qui vous convient et profitez des achats en ligne</Typography>
                </div>
                <div className="plaque__center">
                    <div className="plaque__img">
                        <img src={registerMan} alt="register_image" />
                    </div>
                    <div className="plaque__text">
                        <Typography type="h3" className={classNames(styles.appTitle)}>S'inscrire rapidement et gratuitement</Typography>
                        <p>pour accéder à toutes les fonctions du service</p>
                    </div>
                </div>
                <div className="plaque__right">
                    <LinkButton 
                        url="/register"
                        bgColor="none" 
                        borderColor="#fff" text="Registre"
                        height="100px"
                        
                    />
                </div>
            </div>
        </Container>
    </section>
  )
}
