export const USER_LOGOUT = 'USER_LOGOUT'
export const SWITCH_PRIMARY_SIDEBAR = 'SWITCH_PRIMARY_SIDEBAR'
export const OPEN_PRIMARY_SIDEBAR = 'OPEN_PRIMARY_SIDEBAR'
export const SET_OPENNED_TAG_CATEGORY_ID = 'SET_OPENNED_TAG_CATEGORY_ID'
export const CLOSE_PRIMARY_SIDEBAR = 'CLOSE_PRIMARY_SIDEBAR'
export const OPEN_MAIN_SIDEBAR = 'OPEN_MAIN_SIDEBAR'
export const CLOSE_MAIN_SIDEBAR = 'CLOSE_MAIN_SIDEBAR'
export const SET_AUTH_LOGIN = 'SET_AUTH_LOGIN'
export const SET_TAGS = 'SET_TAGS'
export const ADD_NEW_TAG = 'ADD_NEW_TAG'
export const EDIT_TAG = 'EDIT_TAG'
export const DELETE_TAG = 'DELETE_TAG'
export const SET_CATEGORIES_TAGS = 'SET_CATEGORIES_TAGS'
export const ADD_NEW_CATEGORY_TAG = 'ADD_NEW_CATEGORY_TAG'
export const EDIT_CATEGORY_TAG = 'EDIT_CATEGORY_TAG'
export const DELETE_CATEGORY_TAG = 'DELETE_CATEGORY_TAG'
export const SET_SITES = 'SET_SITES'
export const SET_SELECTED_SITE_ID = 'SET_SELECTED_SITE_ID'