import styles from './styles.module.scss'
//import {createElement } from 'react'
import classNames from 'classnames'

export const Typography = ({children, className, type, required=false, ...props}) => {

  const allClassName = () => `${className??''} ${CustomTagClassName??''}`
  let CustomTag, CustomTagClassName, otherChild

  switch (type) {
    case 'h1':
    case 'title':
      CustomTag = <h1 className={classNames(allClassName(), styles.h1)} {...props}>{children}</h1>
      break
    case 'h2':
    case 'subtitle':
      CustomTag = CustomTag = <h2 className={classNames(allClassName(), styles.h2)} {...props}>{children}</h2>
      break
    case 'h3':
      CustomTag = CustomTag = <h3 className={classNames(allClassName(), styles.h3)} {...props}>{children}</h3>
      break
    case 'h4':
      CustomTag = CustomTag = <h4 className={classNames(allClassName(), styles.h4)} {...props}>{children}</h4>
      break
    case 'h5':
      CustomTag = CustomTag = <h5 className={classNames(allClassName(), styles.h5)} {...props}>{children}</h5>
      break
    case 'label':
      if(required) {
        otherChild = <span className={classNames(styles.labelRequired)}>*</span>
      }
      CustomTag = <>
        <label className={classNames(allClassName(), styles.label)} {...props}>{children} {otherChild??''}</label>
      </>
      break
    case 'p':
    case 'paragraph':
      CustomTag = <p className={classNames(allClassName(), styles.p)} {...props}>{children}</p>
      break
    case 'div':
    case 'block':
      CustomTag = <div className={classNames(allClassName())} {...props}>{children}</div>
      break
    case 'small':
      CustomTag = <small className={classNames(allClassName(), styles.small)} {...props}>{children}</small>
      break
    case 'span':
    default:
      CustomTag = <span className={classNames(allClassName(), styles.span)} {...props}>{children}</span>
      break
  }

  return CustomTag
}