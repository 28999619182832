import { useEffect } from "react"
import { Container } from "../../ui/container/Container"
import Button, {LinkButton} from "../../ui/button/Button"
import { Typography } from "../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../app.module.scss"
import PlaqueRegister from "../includes/plaqueRegister/PlaqueRegister"
import PlaquePhone from "../includes/plaquePhone/PlaquePhone"
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import Layout from "../layouts/Layout"

import gift from '../../../assets/images/defaults/gift.svg'

import './bonus.scss'

export default function Bonus() {
  useEffect(() => {
    document.title = `Rechcompt | Bonus`;
  })
  return (
    <Layout>
      <section className="banner">
        <Container>
          <div className="banner__content">
            <div className="banner__left">
              <Typography type="h1" className={classNames(styles.appTitle)}>
                Les bonus
              </Typography>
              <p>
                Dans le cadre d'une meilleurs proposition de nos services, nous avons prevus pour nos clients plusieurs types de bonus qui ajoute un plus value aux clients fidelles
              </p>
            </div>
            <div className="banner__right banner__right-bonus">
              <div className="banner__right-bonus__box">
                <div className="banner__right-bonus__box__img">
                  <img src={gift} alt="gift_image" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="bonus">
        <Container>
          <div className="bonus__content">
            <div className="bonus__box">
              <div className="bonus__tabs">
                <Tabs>
                  <TabList>
                    <Tab>Le Parrainage</Tab>
                    <Tab>CPA</Tab>
                    <Tab>Cashback</Tab>
                  </TabList>
                  <TabPanel>
                    <div className="bonus__tabs__box">
                      <div className="bonus__tabs__box__item">
                        <Typography type="h3" className={classNames(styles.appTitle)}>
                          Pour profiter de l'option parrainage, vous devez:
                        </Typography>
                        <ul>
                          <li>avoir un compte sur Rechcompt</li>
                          <li>vous assurez que l'email associé à votre compte Rechcompt a bien été vérifié (confirmé)</li>
                          <li>avoir effectué des achats de codes coupons sur Rechcompt pour un montant minimal de <span>20.000 FCFA</span> depuis votre inscription</li>
                        </ul>
                      </div>
                      <div className="bonus__tabs__box__item">
                        <p>Une fois, que vous avez rempli ces conditions, votre lien de parrainage apparaît et vous pouvez ainsi gagner de l'argent en invitant des amis à s'inscrire sur Rechcompt.</p>
                        <Typography type="h3" className={classNames(styles.appTitle)}>
                          Lorsqu'une personne s'inscrit grâce à votre lien de parrainage
                        </Typography>
                        <p><span>vous gagnez 1 point</span>. Le nombre maximum de points que vous pouvez gagner grâce à l'inscription est de 10 points. Au delà de 10 inscriptions, vous ne gagnez plus de points.</p>
                        <p><span>Afin de gagner la somme de 5000 FCFA</span> il faudra atteindre un total <span>de 30 points</span>. Lorsque les personnes que vous invitez à s'inscrire achètent des coupons, vous gagnez également des point.</p>
                      </div>
                      <div className="bonus__tabs__box__item">
                        <p><span>Le montant minimum pour un achat est de 500 FCFA</span>. En tant que parrain, chaque achat de 500 FCFA effectué par un de vos filleuls (parrainés) <span>vous apporte 1 point</span>.</p>
                        <p>
                          Le partenaire apporte 30 clients actifs:
                          Un client actif est un client qui crée un compte Rechcompt et l’active et dont le montant minimum d’achats de codes effectués depuis la date d'inscription a atteint 2000 FCFA.<br/>
                          NB: Le client après inscription peuvent recharger n’importe quel montant inférieur à 2000 FCFA. Ces derniers ne seront actifs que lorsqu’il atteint un total d’achat minimum a partir de 2000 FCFA.
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error  
                    </p>
                  </TabPanel>
                  <TabPanel>
                    <p>
                      Sed ut 
                    </p>
                  </TabPanel>
                </Tabs>
              </div>
              <div className="bonus__actions">
                <LinkButton url="/login" text="Acheter" bgColor="#1B1B1B" borderColor="#1B1B1B" />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <PlaqueRegister/>
      <PlaquePhone/>
    </Layout>
  )
}
