import { useEffect } from "react"
import { Container } from "../../ui/container/Container"
import Button from "../../ui/button/Button"
import { Typography } from "../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../app.module.scss"
import BestPartner from "../includes/bestPartner/BestPartner"
import Slider from "react-slick"
import PartnershipType from "../includes/partnershipType/PartnershipType"
import PlaquePhone from "../includes/plaquePhone/PlaquePhone"
import PlaqueSmall from "../includes/plaqueSmall/PlaqueSmall"
import Layout from "../layouts/Layout"

import moneyPaper1 from '../../../assets/images/defaults/money-paper1.svg'

import './partnership.scss'

export default function Partnership() {
    const settingsBestPartner = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1590,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1,
              }
            },
        ]
    };

    useEffect(() => {
        document.title = `Rechcompt | Partenariat`;
    })

  return (
    <Layout>
        <section className="banner">
            <Container>
                <div className="banner__content banner__content-partnership">
                    <div className="banner__left banner__left-partnership">
                        <Typography type="h1" className={classNames(styles.appTitle)}>Partenariat avec RechCompt</Typography>
                        <Typography type="h3" className={classNames(styles.appTitle)}>
                            Notre politique partenarial consiste à faciliter tous types d'activités incluant des echanges finacieres.
                        </Typography>
                        <p>
                            Nous proposons a toute personnes de pouvoir intégrer Rechcompt dans son site afin d'elargir son marché en toute sécurité.
                        </p>
                    </div>
                    <div className="banner__right banner__right-partnership">
                        <div className="banner__right-partnership__left">
                            <Typography type="h3" className={classNames(styles.appTitle)}>
                                Qui peut être partenaire de RechCompt?
                            </Typography>
                            <p>Vous avez une activité génératrice de revenue en ligne pas, vous proposez des services , articles physique ou numérique, rechcompt sera ravi de vous accompagnez afin de booster vos resultats.</p>
                        </div>
                        <div className="banner__right-partnership__right">
                            <img src={moneyPaper1} alt="moneyPaper1" className="moneypaper1" />
                            <img src={moneyPaper1} alt="moneyPaper2" className="moneypaper2" />
                            <img src={moneyPaper1} alt="moneyPaper3" className="moneypaper3" />
                            <img src={moneyPaper1} alt="moneyPaper4" className="moneypaper4" />
                            <img src={moneyPaper1} alt="moneyPaper5" className="moneypaper5" />
                            <img src={moneyPaper1} alt="moneyPaper6" className="moneypaper6" />
                            <span className="quote">“</span>
                            <span className="t1">Argent</span>
                            <span className="t2">économisé</span>
                            <div className="text-inline">
                                <p>peut être <br/>comptabilisé</p>
                                <span className="t3">gagné</span>
                            </div>
                            <span className="quote">“</span>
                            <p className="signature">© Henry Ford</p>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <section className="partnershipType">
            <Container>
                <div className="partnershipType__content">
                    <div className="partnershipType__top">
                        <Typography type="h2" className={classNames(styles.appTitle)}>Types de partenariats</Typography>
                        <p>Il vous est possible par la suite de changer de type de partenariat en fonction de vos objectif.</p>
                    </div>
                    <div className="partnershipType__bottom">
                        <div className="partnershipType__list">
                            <PartnershipType type="Pro"></PartnershipType>
                            <PartnershipType type="Premium"/>
                            <PartnershipType type="Particulier"/>
                        </div>
                    </div>
                </div>
            </Container>
        </section> 
        <section className="bestPartner">
            <Container>
                <div className="bestPartner__content">
                    <Typography type="h2" className={classNames(styles.appTitle)}>Nos meilleurs partenaires?</Typography>
                    <div className="bestPartner__slide">
                        <Slider {...settingsBestPartner}>
                            <BestPartner/>
                            <BestPartner/>
                            <BestPartner/>
                            <BestPartner/>
                            <BestPartner/>
                            <BestPartner/>
                        </Slider>
                    </div>
                </div>
            </Container>
        </section>
        <PlaqueSmall/>
        <PlaquePhone/>
    </Layout>
  )
}
