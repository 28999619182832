import { Link } from 'react-router-dom'
import { Typography } from "../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../app.module.scss"
import FormItemInput from '../includes/formItem/FormItem'
import Button from "../../ui/button/Button"

import logo from '../../../assets/images/defaults/logo_only.svg'

import './register.scss'

export default function Register() {

  const handleClickInputRadio = (e) => {
    const currentTarget = e.currentTarget
    if(currentTarget.classList.contains('active')){
      currentTarget.classList.remove('active')
    }else{
      currentTarget.classList.add('active')
    }
  }
  return (
    <section className="auth">
        <div className="auth__content auth-register">
            <Link to="/" className='auth__logo'>
              <img src={logo} alt="logo_only" />
              <div className='auth__logo__text'>
                <span>Rech</span>
                <span>Compt</span>
              </div>
            </Link>
            <Typography type="h2" className={classNames(styles.appTitle)}>
              Inscription
            </Typography>
            <div className="auth__card">
              <form action="#" className="auth__form">
                <div className="form-x2">
                  <FormItemInput 
                    labelText='Nom *'
                    type="text"
                    required="required"
                    placeholder="Nom"
                    name="lastname"
                  />
                  <FormItemInput 
                    labelText='Prénom(s) *'
                    type="text"
                    required="required"
                    placeholder="Prénom(s)"
                    name="firstname"
                  />
                </div>
                <div className="form-x2">
                  <FormItemInput 
                    labelText='E-mail *'
                    type="email"
                    required="required"
                    placeholder="e-mail"
                    name="email"
                  />
                  <FormItemInput 
                    labelText='Téléphone *'
                    type="number"
                    required="required"
                    placeholder=""
                    name="number"
                  />
                </div>
                <div className="form-x2">
                  <FormItemInput 
                    labelText='Mot de passe *'
                    type="password"
                    required="required"
                    placeholder="votre mot de passe"
                    name="password"
                  />
                  <FormItemInput 
                    labelText='Confirmez mot de passe *'
                    type="password"
                    required="required"
                    placeholder="Confirmez votre mot de passe"
                    name="confirmPassword"
                  />
                </div>
                <div className='form-bottom'>
                  <Button text="S'inscrire" size="18px" height="45px" borderColor="#008981"/>
                  <div className='form-radio'>
                    <input 
                      type="radio" 
                      name="condition" 
                      data-radio="radio" 
                      className=''
                      onClick={handleClickInputRadio}  
                    />
                    <p>J’accepte les conditions de confidentialté* <Link to="#">(Lire les conditions)</Link></p>
                  </div>
                </div>
              </form>
              <p>Vous déjà un compte? <Link to="/login">Se connecter</Link></p>
            </div>
        </div>
    </section>
  )
}
