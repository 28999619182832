import { useState, useEffect} from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Header from './components/pages/layouts/header/Header'
import Footer from './components/pages/layouts/footer/Footer'
import ScrollToTop from './components/pages/includes/scrollToTop/ScrollToTop'

import Login from './components/pages/login/Login'
import Register from './components/pages/register/Register'
import Home from './components/pages/home/Home'
import Services from './components/pages/services/Services'
import Faq from './components/pages/faq/Faq'
import Actualities from './components/pages/actualities/Actualities'
import Games from './components/pages/games/Games'
import Contact from './components/pages/contact/Contact'
import Partnership from './components/pages/partnership/Partnership'
import Bonus from './components/pages/bonus/Bonus'
import Notfound from './components/pages/notfound/Notfound'

const App = () => {
  const [locate, setLocation] = useState('')

  const handleSetLocation = () =>{
    let url = (window.location.pathname.split('/')[1]==='home') ? 'home' : window.location.pathname.split('/')[1]
    setLocation(url)
  }

  useEffect(()=>{
    const urlPage = window.location.pathname.split('/')[1]
    setLocation(urlPage)
  },[]) 


  return (
    <div className={`wrapper ${locate}`} onClick={handleSetLocation}>
      <ScrollToTop/>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/faq" element={<Faq/>} />
        <Route path="/actualities" element={<Actualities/>} />
        <Route path="/bonus" element={<Bonus/>} />
        <Route path="/games" element={<Games/>} />
        <Route path="/contacts" element={<Contact/>} />
        <Route path="/partnership" element={<Partnership/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="*" element={<Notfound/>} />
      </Routes>
    </div>
  )
}
export default App
