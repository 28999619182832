import { useEffect } from "react"
import { Link } from "react-router-dom"
import { Container } from "../../ui/container/Container"
import Button, {LinkButton} from "../../ui/button/Button"
import { Typography } from "../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../app.module.scss"
import Input from "../../ui/input/Input"
import PlayButton from "../includes/playButton/PlayButton"
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import FaqMedia from "../includes/faqMedia/FaqMedia"
import PlaqueRegister from "../includes/plaqueRegister/PlaqueRegister"
import Layout from "../layouts/Layout"

import bannerImg from '../../../assets/images/defaults/fack_banner_img.png'
import faq1 from '../../../assets/images/defaults/faq1.png'
import faq2 from '../../../assets/images/defaults/faq2.png'
import faq3 from '../../../assets/images/defaults/faq3.png'

import './faq.scss'

export default function Faq() {

  const handleClickInputRadio = (e) => {
    const currentTarget = e.currentTarget
    if(currentTarget.classList.contains('active')){
      currentTarget.classList.remove('active')
    }else{
      currentTarget.classList.add('active')
    }
  }

  useEffect(() => {
    document.title = `Rechcompt | FAQ`;
  })
  return (
    <Layout>
      <section className="banner">
        <Container>
          <div className="banner__content banner__content-faq">
            <div className="banner__left banner__left__faq">
              <Typography type="h1" className={classNames(styles.appTitle)}>
                Trouvez des reponses à vos préocupations
              </Typography>
              <p>Consultez les responses aux questions les plus frequants</p>
              <LinkButton url="/register" text="S'inscrir" />
              <div className="banner__left__faq__form">
                <Typography type="h3" className={classNames(styles.appTitle)}>
                  Recherche par mot-clé
                </Typography>
                <form action="#" className="">
                  <Input className="key_word" />
                  <div className="banner__left__faq__form__box">
                    <p>Je veux obtenir un résultat dans</p>
                    <div className="banner__left__faq__form__box__field">
                      <Input type="radio" name="type" onClick={handleClickInputRadio}/>
                      <span>Vidéo</span>
                    </div>
                    <div className="banner__left__faq__form__box__field">
                      <Input type="radio" name="type" onClick={handleClickInputRadio}/>
                      <span>Texte</span>
                    </div>
                  </div>
                  <Button text="Localiser" />
                </form>
              </div>
              <div className="banner__left__faq__desc">
                <Typography type="h2" className={classNames(styles.appTitle)}>
                  Consultez les responses aux questions les plus frequants
                </Typography>
                <p>Si vous ne trouvez pas la réponse à votre question: regardez la vidéo ci-dessous ou utilisez le formulaire de recherche</p>
              </div>
            </div>
            <div className="banner__right banner__right__faq">
              <Link to="#" className="banner__right__faq__media">
                <img src={bannerImg} alt="image__banner" />
                <span className="play-icon"><PlayButton /></span>
              </Link>
              <div className="banner__right__faq__tabs">
                <Tabs>
                  <TabList>
                    <Tab>Comment s'inscrir?</Tab>
                    <Tab>Comment devenir partenaire?</Tab>
                  </TabList>
                  <TabPanel>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                    </p>
                    <div>

                    </div>
                  </TabPanel>
                  <TabPanel>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. 
                    </p>
                    <div>
                      
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="faq">
        <Container>
          <div className="faq__content">
            <div className="faq__card">
              <Typography type="h2" className={classNames(styles.appTitle)}>
                Pour une meilleur comprenions, veuillez consulter nos videos
              </Typography>
              <div className="faq__list">
                <FaqMedia 
                  source={faq1}
                  title="Comment faire un transfert a l'international"
                />
                <FaqMedia 
                  source={faq2}
                  title="Comment faire un transfert a l'international"
                />
                <FaqMedia 
                  source={faq3}
                  title="Comment faire un transfert a l'international"
                />
                <FaqMedia 
                  source={faq1}
                  title="Comment faire un transfert a l'international"
                />
                <FaqMedia 
                  source={faq2}
                  title="Comment faire un transfert a l'international"
                />
                <FaqMedia 
                  source={faq3}
                  title="Comment faire un transfert a l'international"
                />
              </div>
              <Button text="Plus de vidéos" />
            </div>
          </div>
        </Container>
      </section>
      <PlaqueRegister/>
    </Layout>
  )
}
