import { useEffect } from "react"
import { Link } from "react-router-dom"
import DefaultBanner from "../includes/defaultBanner/DefaultBanner"
import { Container } from "../../ui/container/Container"
import { Typography } from "../../ui/typography/Typography"
import Button, { LinkButton } from "../../ui/button/Button"
import classNames from 'classnames'
import styles from "../../../app.module.scss"
import FormItemInput from "../includes/formItem/FormItem"
import Slider from "react-slick"
import Actuality from "../includes/actuality/Actuality"
import Layout from "../layouts/Layout"

import instructionImg1 from "../../../assets/images/defaults/service-woman.svg"
import instructionImg2 from "../../../assets/images/defaults/service-man.svg"
import registerMan from "../../../assets/images/defaults/register-man.svg"
import serviceHande from "../../../assets/images/defaults/service-hand.svg"
import bannerCardImg1 from '../../../assets/images/defaults/banner-badge1.svg'
import bannerCardImg2 from '../../../assets/images/defaults/banner-badge2.svg'
import ubaImg from '../../../assets/images/defaults/uba.svg'
import diarrasImg from '../../../assets/images/defaults/ets-diarras.svg'
import globalTechImg from '../../../assets/images/defaults/globaltech.svg'
import kkiapayImg from '../../../assets/images/defaults/kkiapay.svg'
import womanPartnerImg from '../../../assets/images/defaults/partner-woman.svg'
import catImg from '../../../assets/images/defaults/partner-dog.svg'
import flowerPartnerImg from '../../../assets/images/defaults/partner-flower.svg'
import orangePartnerImg from '../../../assets/images/defaults/partner-orange.svg'
import radioPartnerImg from '../../../assets/images/defaults/partner-radio.svg'
import workStep1 from '../../../assets/images/defaults/work-step1.svg'
import workStep2 from '../../../assets/images/defaults/work-step2.svg'
import workStep3 from '../../../assets/images/defaults/work-step3.svg'
import workStep4 from '../../../assets/images/defaults/work-step4.svg'
import android from '../../../assets/images/defaults/android.svg'
import ios from '../../../assets/images/defaults/ios.svg'
import mtn from '../../../assets/images/defaults/mtn.svg'
import moov from '../../../assets/images/defaults/moov.svg'
import mastercard from '../../../assets/images/defaults/mastercard.svg'
import visa from '../../../assets/images/defaults/visa.svg'
import benin from '../../../assets/images/defaults/benin.svg'
import congo from '../../../assets/images/defaults/congo.svg'
import cameroon from '../../../assets/images/defaults/cameroon.svg'
import coteDivoir from '../../../assets/images/defaults/cote-divoir.svg'
import burkina from '../../../assets/images/defaults/burkina.svg'
import world from '../../../assets/images/defaults/world.svg'
import qa from '../../../assets/images/defaults/qa.svg'
import avatar from '../../../assets/images/defaults/avatar.png'
import actu from '../../../assets/images/defaults/actu.png'

import "./home.scss"

export default function Home() {
    const settingsTrustUs = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 1,
              }
            },
        ]
    };
    const settingsActualities = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 930,
              settings: {
                slidesToShow: 1,
              }
            },
        ]
    };

    useEffect(() => {
        document.title = `Rechcompt`;
    })

  return (
    <Layout>
        <DefaultBanner
            title="Achetez des codes coupons"
            text="pour vos recharges et achats divers chez nos partenaires!"
            cardTitle1="Votre argent est protégé"
            cardText1="Si nécessaire, vous recevrez une indemnisation"
            cardTitle2="Pratique, sûr, anonyme"
            cardText2="Un partenaire fiable pour les transactions en ligne"
        />
        <section className="instruction">
            <Container>
                <div className="instruction__content">
                    <div className="instruction__top">
                        <div className="instruction__top__left">
                            <div className="instruction__top__left__card">
                                <div className="instruction__top__left__do">
                                    <div className="instruction__top__left__img">  
                                        <img src={instructionImg1} alt="instruction_image_1" />
                                        <img src={instructionImg2} alt="instruction_image_2" />
                                    </div>
                                    <div className="instruction__top__left__text">
                                        <Typography type="h3" className={classNames(styles.appTitle)}>En utilisant notre service, vous pourrez:</Typography>
                                        <p>Faire des achats en ligne, transférer de l'argent et recevoir des virements bancaires sans divulguer vos données personnelles et sans même disposer d'une carte bancaire.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="instruction__top__right__card">
                                <img src={bannerCardImg1} alt="" className="other-img pin1" />
                                <img src={bannerCardImg2} alt="" className="other-img pin2" />
                                <div className="instruction__top__right__top">
                                    <img src={serviceHande} alt="service_hand" />
                                    <p>
                                        Les codes Rechcompt sont des coupons générés sur notre plateforme qui vous aident à effectuer vos transactions chez nos partenaires.
                                    </p>
                                </div>
                                <div className="instruction__top__right__bottom">
                                    <Typography type="h3" className={classNames(styles.appTitle)}>Le principe est simple:</Typography>
                                    <ul>
                                        <li>créez un compte sur Rechcompt</li>
                                        <li>échangez votre argent contre un code Rechcompt équivalent à ce montant</li>
                                        <li>utilisez le code Rechcompt pour effectuer vos transactions chez les partenaires affiliés à notre système</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="instruction__top__right">
                            <div className="instruction__top__right__img">
                                <img src={registerMan} alt="" />
                            </div>
                            <div className="instruction__top__right__text">
                                <Typography type="h3" className={classNames(styles.appTitle)}>Inscrivez-vous gratuitement</Typography>
                                <p>pour bénéficier de nos services auprès de nos partenaires</p>
                                <LinkButton 
                                    url="/register"
                                    text='Registre'
                                    bgColor='transparent'
                                    height='48px'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="instruction__bottom">
                        <div className="instruction__bottom__item">
                            <strong>Vous désirez faire des achats en ligne en réduisant complètement vos frais de transactions ?</strong>
                            <p>Achetez un code Rechcompt. Utilisez ensuite ce code pour payer chez nos partenaires pour l’équivalent du montant que vous avez décaissé.</p>
                        </div>
                        <div className="instruction__bottom__item">
                            <strong>Vous désirez recharger localement vos comptes de paris avec de la monnaie locale ?</strong>
                            <p>Achetez un code Rechcompt. Utilisez ensuite ce code sur le site du partenaire pour recharger votre compte pour l’équivalent du montant de la recharge.</p>
                        </div>
                        <div className="instruction__bottom__item">
                            <strong>Vous êtes dans l’obligation d’effectuer un achat par carte bancaire alors que vous n’en possédez pas une ?</strong>
                            <p>Générez un code Rechcompt et effectuez votre opération en toute sécurité.</p>
                        </div>
                        <div className="instruction__bottom__item">
                            <strong>Vous désirez recharger votre carte prépayée ?</strong>
                            <p>Rechcompt vous aide en un clic.</p>
                        </div>
                        <div className="instruction__bottom__item">
                            <strong>Vous voulez recevoir de l’argent en provenance de nos partenaires ?</strong>
                            <p>C’est facile et c’est rapide.</p>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <section className="partners">
            <Container>
                <div className="partners__content">
                    <img src={catImg} alt="cat" />
                    <img src={flowerPartnerImg} alt="flower" />
                    <img src={womanPartnerImg} alt="woman" />
                    <img src={radioPartnerImg} alt="radio" />
                    <Typography type="h2" className={classNames(styles.appTitle)}>Nos partenaires</Typography>
                    <p>Nous travaillons chaque jour pour établir de nouveaux partenariats qui vous rendent la vie encore plus facile</p>
                    <div className="partners__card">
                        <img src={orangePartnerImg} alt="orange" />
                        <div className="partners__list">
                            <div className="partners__item">
                                <img src={ubaImg} alt="uba" />
                            </div>
                            <div className="partners__item">
                                <img src={diarrasImg} alt="diarras" />
                            </div>
                            <div className="partners__item">
                                <img src={globalTechImg} alt="blobaltech" />
                            </div>
                            <div className="partners__item">
                                <img src={kkiapayImg} alt="kkiapay" />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <section className="infos">
            <Container>
                <div className="infos__content">
                    <div className="infos__left">
                        <Typography type="h2" className={classNames(styles.appTitle)}>Comment ça marche?</Typography>
                        <div className="infos__list">
                            <div className="infos__item">
                                <div className="infos__item__left">
                                    <img src={workStep1} alt="workStep1" />
                                    <span>1</span>
                                </div>
                                <div className="infos__item__right">
                                    <p>Créez votre compte en remplissant le formulaire d’inscription.Créez votre compte en remplissant le formulaire d’inscription.</p>
                                </div>
                            </div>
                            <div className="infos__item">
                                <div className="infos__item__left">
                                    <img src={workStep2} alt="workStep2" />
                                    <span>2</span>
                                </div>
                                <div className="infos__item__right">
                                    <p>Achetez vos codes Rechcompt et facilitez vos transactions chez nos nombreux partenaires.</p>
                                </div>
                            </div>
                            <div className="infos__item">
                                <div className="infos__item__left">
                                    <img src={workStep3} alt="workStep3" />
                                    <span>3</span>
                                </div>
                                <div className="infos__item__right">
                                    <p>Achetez une carte prépayée pour accéder à un univers immense de transactions.</p>
                                </div>
                            </div>
                            <div className="infos__item">
                                <div className="infos__item__left">
                                    <img src={workStep4} alt="workStep4" />
                                    <span>4</span>
                                </div>
                                <div className="infos__item__right">
                                    <p>Vous voulez recevoir de l’argent en provenance de nos partenaires? Accédez à l’interface et profitez-en.</p>
                                </div>
                            </div>
                        </div>
                        <div className="infos__appLInk">
                            <div className="infos__appLInk__left">
                                <Link to="#">
                                    <img src={android} alt="android" />
                                    <span>Android</span>
                                </Link>
                                <Link to="#">
                                    <img src={ios} alt="ios" />
                                    <span>IOS</span>
                                </Link>
                            </div>
                            <div className="infos__appLInk__right">
                                <Typography type="h3" className={classNames(styles.appTitle)}>Téléchargez notre<br/> application</Typography>
                                <p>sur votre téléphone</p>
                            </div>
                        </div>
                    </div>
                    <div className="infos__right">
                        <div className="infos__right__top">
                            <Typography type="h2" className={classNames(styles.appTitle)}>Nos moyens de paiement</Typography>
                            <div className="infos__right__top__list">
                                <div className="infos__right__top__item">
                                    <img src={mtn} alt="mtn" />
                                </div>
                                <div className="infos__right__top__item">
                                    <img src={mastercard} alt="mastercard" />
                                </div>
                                <div className="infos__right__top__item">
                                    <img src={moov} alt="moov" />
                                </div>
                                <div className="infos__right__top__item">
                                    <img src={visa} alt="visa" />
                                </div>
                            </div>
                        </div>
                        <div className="infos__right__bottom">
                            <div className="infos__right__countries">
                                <Typography type="h2" className={classNames(styles.appTitle)}>Pays dans lesquels nous opérons</Typography>
                                <div className="infos__right__countries__list">
                                    <div className="infos__right__countries__item">
                                        <img src={benin} alt="benin" />
                                        <span>Benin</span>
                                    </div>
                                    <div className="infos__right__countries__item">
                                        <img src={congo} alt="congo" />
                                        <span>Congo</span>
                                    </div>
                                    <div className="infos__right__countries__item">
                                        <img src={cameroon} alt="cameroon" />
                                        <span>Cameroon</span>
                                    </div>
                                    <div className="infos__right__countries__item">
                                        <img src={coteDivoir} alt="coteDivoir" />
                                        <span>Côte d'Ivoire</span>
                                    </div>
                                    <div className="infos__right__countries__item">
                                        <img src={burkina} alt="burkina" />
                                        <span>Burkina Faso</span>
                                    </div>
                                </div>

                            </div>
                            <div className="infos__right__bottom__desc">
                                <div className="infos__right__bottom__desc__img">
                                    <img src={world} alt="world" />
                                </div>
                                <div className="infos__right__bottom__desc__text">
                                    <strong>Nous travaillons constamment à l'amélioration de notre service et à l'extension géographique de notre présence</strong>
                                    <p>Si vous ne trouvez pas le pays dont vous avez besoin dans la liste, n'hésitez pas à vous abonner à notre lettre d'information électronique et à nos réseaux sociaux, où nous publions les derniers changements.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <section className="contact">
            <Container>
                <div className="contact__content">
                    <div className="contact__left">
                        <div className="contact__left__top">
                            <Typography type="h2" className={classNames(styles.appTitle)}>Contactez-nous</Typography>
                            <p>Des questions? Des préoccupations? Des suggestions? Vous comptez beaucoup pour nous. Laissez-nous un message!</p>
                        </div>
                        <div className="contact__left__bottom contact-bottom">
                            <form action="#" className="contact-form">
                                <FormItemInput 
                                    labelText='Nom et prénoms'
                                    required="required"
                                    placeholder="Nom et prénoms*"
                                    name="name"
                                />
                                <div className="form-x2">
                                    <FormItemInput 
                                        labelText='Téléphone'
                                        type="number"
                                        required="required"
                                        placeholder="Téléphone *"
                                        name="phone"
                                    />
                                    <FormItemInput 
                                        labelText='E-mail'
                                        type="email"
                                        required="required"
                                        placeholder="E-mail *"
                                        name="email"
                                    />
                                </div>
                                <FormItemInput 
                                    labelText='Objet'
                                    required="required"
                                    placeholder="Objet *"
                                    name="object"
                                />
                                <FormItemInput 
                                    labelText='Votre message'
                                    required="required"
                                    placeholder="Nom et prénoms *"
                                    name="message"
                                />
                                <Button text="Envoyer" bgColor="none"/>
                            </form>
                        </div>
                    </div>
                    <div className="contact__right">
                        <img src={qa} alt="qa" />
                        <Typography type="h3" className={classNames(styles.appTitle)}>Pour vous faciliter la tâche</Typography>
                        <p>nous avons rassemblé les questions les plus fréquentes et y avons répondu sur la page FAQ, y compris dans un format vidéo pratique.</p>
                        <LinkButton url="/faq" text="Voir"/>
                    </div>
                </div>
            </Container>
        </section>
        <section className="trust-us">
            <Container>
                <div className="trust-us__content">
                    <div className="trust-us__left">
                        <Typography type="h2" className={classNames(styles.appTitle)}>Ils nous ont fait confiance</Typography>
                        <p>Il s'agit ici de représenter les différents fournisseurs avec lesquels nous travaillons</p>
                        <p>Vous pouvez laisser vos commentaires <Link to="#">ici</Link></p>
                    </div>
                    <div className="trust-us__right">
                        <div className="trust-us__right__slide">
                            <Slider {...settingsTrustUs}>
                                <div className="trust-us__right__item">
                                    <p>Grâce à sa plateforme de paiement, KKiaPay nous permet de rentabiliser nos services sur le plan national à travers une solution de paiement facile à intégrer, 100% béninoise.</p>
                                    <div className="trust-us__right__item__profil">
                                        <div className="trust-us__right__item__img">
                                            <img src={avatar} alt="avatar_user" />
                                        </div>
                                        <div className="trust-us__right__item__text">
                                            <strong>Charbel ZINSOU</strong>
                                            <span>Auteur sur CAPPFM</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="trust-us__right__item">
                                    <p>Grâce à sa plateforme de paiement, KKiaPay nous permet de rentabiliser nos services sur le plan national à travers une solution de paiement facile à intégrer, 100% béninoise.</p>
                                    <div className="trust-us__right__item__profil">
                                        <div className="trust-us__right__item__img">
                                            <img src={avatar} alt="avatar_user" />
                                        </div>
                                        <div className="trust-us__right__item__text">
                                            <strong>Charbel ZINSOU</strong>
                                            <span>Auteur sur CAPPFM</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="trust-us__right__item">
                                    <p>Grâce à sa plateforme de paiement, KKiaPay nous permet de rentabiliser nos services sur le plan national à travers une solution de paiement facile à intégrer, 100% béninoise.</p>
                                    <div className="trust-us__right__item__profil">
                                        <div className="trust-us__right__item__img">
                                            <img src={avatar} alt="avatar_user" />
                                        </div>
                                        <div className="trust-us__right__item__text">
                                            <strong>Charbel ZINSOU</strong>
                                            <span>Auteur sur CAPPFM</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="trust-us__right__item">
                                    <p>Grâce à sa plateforme de paiement, KKiaPay nous permet de rentabiliser nos services sur le plan national à travers une solution de paiement facile à intégrer, 100% béninoise.</p>
                                    <div className="trust-us__right__item__profil">
                                        <div className="trust-us__right__item__img">
                                            <img src={avatar} alt="avatar_user" />
                                        </div>
                                        <div className="trust-us__right__item__text">
                                            <strong>Charbel ZINSOU</strong>
                                            <span>Auteur sur CAPPFM</span>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <section className="actualities">
            <Container>
                <div className="actualities__content">
                    <Typography type="h2" className={classNames(styles.appTitle)}>Actualités</Typography>
                    <div className="actualities__card">
                        <div className="actualities__slide">
                            <Slider {...settingsActualities}>
                                <Actuality
                                    image={actu}
                                    category="Nom de la catégorie"
                                    title="Un titre dont vous avez tout compris et plus encore"
                                    text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
                                />
                                <Actuality
                                    image={actu}
                                    category="Nom de la catégorie"
                                    title="Un titre dont vous avez tout compris et plus encore"
                                    text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
                                />
                                <Actuality
                                    image={actu}
                                    category="Nom de la catégorie"
                                    title="Un titre dont vous avez tout compris et plus encore"
                                    text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
                                />
                                <Actuality
                                    image={actu}
                                    category="Nom de la catégorie"
                                    title="Un titre dont vous avez tout compris et plus encore"
                                    text="Notre situation incroyable, que nous avons décidé de rapporter pour que vous soyez tous là à vous dire: putain de bite, c'est foutu! Je tremble d'extase, ma vie ne sera plus jamais la même. Prenez tout mon argent!"
                                />
                            </Slider>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    </Layout>
  )
}
