import { useEffect } from "react"
import { Link } from "react-router-dom"
import { Container } from "../../ui/container/Container"
import Button from "../../ui/button/Button"
import { Typography } from "../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../app.module.scss"
import Input from "../../ui/input/Input"
import PlaqueSmall from "../includes/plaqueSmall/PlaqueSmall"
import FormItemInput from "../includes/formItem/FormItem"
import { Icon } from "../../ui/icons/Icon"
import Layout from "../layouts/Layout"

import benin from '../../../assets/images/defaults/benin.svg'
import congo from '../../../assets/images/defaults/congo.svg'
import cameroon from '../../../assets/images/defaults/cameroon.svg'
import coteDivoir from '../../../assets/images/defaults/cote-divoir.svg'
import burkina from '../../../assets/images/defaults/burkina.svg'
import world from '../../../assets/images/defaults/world.svg'
import managerAvatar from '../../../assets/images/defaults/user_avatar.svg'

import './contact.scss'

export default function Contact() {
  useEffect(() => {
    document.title = `Rechcompt | Contact`;
  })
  return (
    <Layout>
      <section className="contact">
        <Container>
          <div className="contact__content contact__content-p">
            <div className="contact__box">
              <div className="contact__left">
                <div className="contact__left__top">
                  <Typography type="h2" className={classNames(styles.appTitle)}>Nous contacter</Typography>
                  <p></p>
                </div>
                <div className="contact__left__bottom contact-bottom">
                  <form action="#" className="contact-form">
                    <FormItemInput 
                      labelText='Nom et prénoms'
                      required="required"
                      placeholder="Nom et prénoms*"
                      name="name"
                    />
                    <div className="form-x2">
                      <FormItemInput 
                        labelText='Téléphone'
                        type="number"
                        required="required"
                        placeholder="Téléphone*"
                        name="phone"
                      />
                      <FormItemInput 
                        labelText='E-mail'
                        type="email"
                        required="required"
                        placeholder="Nom et prénoms*"
                        name="email"
                      />
                    </div>
                    <FormItemInput 
                      labelText='Objet'
                      required="required"
                      placeholder="Objet**"
                      name="object"
                    />
                    <FormItemInput 
                      labelText='Votre message'
                      required="required"
                      placeholder="Nom et prénoms*"
                      name="message"
                    />
                    <Button text="Envoyer" bgColor="none"/>
                  </form>
                </div>
              </div>
              <div className="infos__right">
                <div className="infos__right__bottom">
                  <div className="infos__right__countries">
                    <Typography type="h2" className={classNames(styles.appTitle)}>Pays dans lesquels nous opérons</Typography>
                    <div className="infos__right__countries__list">
                      <div className="infos__right__countries__item">
                        <img src={benin} alt="benin" />
                        <span>Benin</span>
                      </div>
                      <div className="infos__right__countries__item">
                        <img src={congo} alt="congo" />
                        <span>Congo</span>
                      </div>
                      <div className="infos__right__countries__item">
                        <img src={cameroon} alt="cameroon" />
                        <span>Cameroon</span>
                      </div>
                      <div className="infos__right__countries__item">
                        <img src={coteDivoir} alt="coteDivoir" />
                        <span>Côte d'Ivoire</span>
                      </div>
                      <div className="infos__right__countries__item">
                        <img src={burkina} alt="burkina" />
                        <span>Burkina Faso</span>
                      </div>
                    </div>

                  </div>
                  <div className="infos__right__bottom__desc">
                    <div className="infos__right__bottom__desc__img">
                      <img src={world} alt="world" />
                    </div>
                    <div className="infos__right__bottom__desc__text">
                      <strong>Nous travaillons constamment à l'amélioration de notre service et à l'extension géographique de notre présence</strong>
                      <p>Si vous ne trouvez pas le pays dont vous avez besoin dans la liste, n'hésitez pas à vous abonner à notre lettre d'information électronique et à nos réseaux sociaux, où nous publions les derniers changements.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact__right contact__right-infos">
              <div className="contact__right-infos__top">
                <Typography type="h2" className={classNames(styles.appTitle)}>Nos managers</Typography>
                <div className="contact__right-infos__top__list">
                  <div className="contact__right-infos__top__item">
                    <div className="contact__right-infos__top__img active">
                      <img src={managerAvatar} alt="manager_avatar" />
                    </div>
                    <span>Nom du manager</span>
                  </div>
                  <div className="contact__right-infos__top__item">
                    <div className="contact__right-infos__top__img">
                      <img src={managerAvatar} alt="manager_avatar" />
                    </div>
                    <span>Nom du manager</span>
                  </div>
                  <div className="contact__right-infos__top__item">
                    <div className="contact__right-infos__top__img">
                      <img src={managerAvatar} alt="manager_avatar" />
                    </div>
                    <span>Nom du manager</span>
                  </div>
                  <div className="contact__right-infos__top__item">
                    <div className="contact__right-infos__top__img active">
                      <img src={managerAvatar} alt="manager_avatar" />
                    </div>
                    <span>Nom du manager</span>
                  </div>
                </div>
                <Typography type="h2" className={classNames(styles.appTitle)}>Autres</Typography>
                <div className="contact__right-infos__top__mails">
                  <Link to="mailto:contacts@rechcompt.com">contacts@rechcompt.com</Link>
                  <Link to="mailto:reclamations@rechcompt.com">reclamations@rechcompt.com</Link>
                  <Link to="mailto:partenariat@rechcompt.com">partenariat@rechcompt.com</Link>
                </div>
              </div>
              <div className="contact__right-infos__bottom">
                <Typography type="h2" className={classNames(styles.appTitle)}>Nos réseaux sociaux</Typography>
                <div className="contact__right-infos__bottom__rs">
                  <Link to="/" className={classNames(styles.appIconBox)}>
                    <Icon name="instagram" size="2.5rem" className={classNames(styles.appIcon)}></Icon>
                  </Link>
                  <Link to="/" className={classNames(styles.appIconBox)}>
                    <Icon name="twitter" size="2.5rem" className={classNames(styles.appIcon)}></Icon>
                  </Link>
                  <Link to="/" className={classNames(styles.appIconBox)}>
                    <Icon name="facebook" size="2.5rem" className={classNames(styles.appIcon)}></Icon>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <PlaqueSmall/>
    </Layout>
  )
}
