import { Link } from "react-router-dom"
import Button from "../../../ui/button/Button"
import { Typography } from "../../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../../app.module.scss"
import PlayButton from '../playButton/PlayButton'

import './faqMedia.scss'

export default function FaqMedia(props) {
  const {link, source, id, title} = props
  return (
    <>
      <Link to={link} className="faq__item">
        <div className="faq__img">
          <img src={source} alt="fag_img"/>
          <span><PlayButton width="120px"/></span>
        </div>
        <Typography type="h3" className={classNames(styles.appTitle)}>
          {title}
        </Typography>
      </Link>
    </>
  )
}
