import { Typography } from "../../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../../app.module.scss"
import Button, {LinkButton} from '../../../ui/button/Button'

import './bestPartner.scss'

export default function BestPartner() {
  return (
    <div className="bestPartner__item">
        <div className="bestPartner__item__img">

        </div>
        <Typography type="h3" className={classNames(styles.appTitle)}>Nom du partenaire</Typography>
        <p>Vous avez une activité génératrice de revenue en ligne pas, vous proposez des services , articles physique ou numérique, rechcompte sera ravi de vous accompagnez afin de booster vos resultats.</p>
        <LinkButton to="/" text="En savoir plus" height="42px" size="14px" />
    </div>
  )
}
