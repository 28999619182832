
import game from '../../../../assets/images/defaults/game.png'
import Button, {LinkButton} from "../../../ui/button/Button"
import { Typography } from "../../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../../app.module.scss"
import './game.scss'

export default function Game() {
  return (
    <div className='games__item'>
        <div className="games__left">
            <img src={game} alt="jeux_et_concours" />
        </div>
        <div className="games__right">
            <Typography type="h3" className={classNames(styles.appTitle)}>Nom du jeux concours</Typography>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id.
            </p>
            <LinkButton
                to="/"
                text="Acheter"
                height="41px"
                size="18px"
            />
        </div>
    </div>
  )
}
