import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import './button.scss'

export default function Button({color, bgColor, text, height, borderColor, size}) {
  const colorPrimary = '#fff'
  const bgColorPrimary =   'linear-gradient(151deg, #04C8A5 16.25%, #009B7F 88.84%)'
  const fontFamily = '"Krona One", sans-serif'

  const useStyles = createUseStyles({
    button: {
      textAlign: 'center',
      border:`2px solid  ${borderColor??colorPrimary}`,
      borderRadius:'10px' ,
      height: height??'60px',
      lineHeight: height??'60px',
      paddingLeft:'55px',
      paddingRight: '55px',
      color: color??colorPrimary,
      background: bgColor??bgColorPrimary,
      fontFamily:fontFamily,
      fontSize: size??'24px',
    }

  })
  
  const classes = useStyles()
  return (
    <button className={classNames(classes.button)+ ' btn-btn'}>{text}</button>
  )
}

export function LinkButton({color, bgColor, text, height, borderColor, size, url}) {
  const colorPrimary = '#fff'
  const bgColorPrimary =   'linear-gradient(151deg, #04C8A5 16.25%, #009B7F 88.84%)'
  const fontFamily = '"Krona One", sans-serif'

  const useStyles = createUseStyles({
    button: {
      display:'inline-block',
      textAlign: 'center',
      border:`2px solid  ${borderColor??colorPrimary}`,
      borderRadius:'10px' ,
      height: height??'60px',
      lineHeight: height??'60px',
      paddingLeft:'55px',
      paddingRight: '55px',
      color: color??colorPrimary,
      background: bgColor??bgColorPrimary,
      fontFamily:fontFamily,
      fontSize: size??'24px',
    }

  })
  
  const classes = useStyles()
  return (
    <Link to={url} className={classNames(classes.button)+ ' link-btn'}>{text}</Link>
  )
}