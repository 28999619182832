import { Link } from "react-router-dom"
import { Container } from "../../../ui/container/Container"
import { Typography } from "../../../ui/typography/Typography"
import classNames from 'classnames'
import styles from "../../../../app.module.scss"

import './plaquePhone.scss'

import ios from '../../../../assets/images/defaults/ios.svg'
import android from '../../../../assets/images/defaults/android.svg'
import phone from '../../../../assets/images/defaults/phone.svg'
import boy from '../../../../assets/images/defaults/boy.svg'

export default function PlaquePhone() {
  return (
    <section className="plaque-phone">
        <Container>
            <div className="plaque-phone__content">
                <div className="plaque-phone__card">
                    <Typography type="h3" className={classNames(styles.appTitle)}>Téléchargez notre application</Typography>
                    <p>sur votre téléphone</p>
                    <img src={phone} alt="phone" className="phone" />
                    <img src={boy} alt="boy" className="boy" />
                    <div className="plaque-phone__list">
                        <div className="plaque-phone__item">
                            <Link to="#">
                                <img src={android} alt="android" />
                            </Link>
                            <span>Android</span>
                        </div>
                        <div className="plaque-phone__item">
                            <Link to="#">
                                <img src={ios} alt="ios" />
                            </Link>
                            <span>IOS</span>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    </section>
  )
}
